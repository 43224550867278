import React, {useEffect, useState} from 'react'
import SearchService from '../Shared/Services/Search'
import {
    ISearchResult,
    SearchResultItem,
} from '../Shared/Services/Search/interfaces'
import { StatusCode } from '../Shared/Services/Response.types'
import Search from './../SearchApp/Search'
import {SearchSettings, SearchTheme} from '@apps/SearchApp/types'
import {CUSTOMER_STATE_CONTEXT} from "@frontendUtils/browser-storage";
import {getLocale} from "@apps/Shared/Utils/Utils";
import {useGetStartPageModelQuery} from "@apps/Shared/GraphQL/generated";

function fetchSearchResults(
    query: string,
    features: string[],
    setResults: Function,
    customerType: string
) {
    let response: ISearchResult = {}

    SearchService.GetSearchResults(query, features, customerType)
        .then(result => {
            const status = result.status
            if (status === StatusCode.OK) {
                response = result
                setResults(response.searchHits)
            }
        })
        .catch(err => {
            console.log(err)
        })
}

function getSearchSettings(): SearchSettings {
    const locale = getLocale();
    const { data } = useGetStartPageModelQuery({ locale: locale });
    let searchSettings: SearchSettings = {};

    if (data) {
        searchSettings.popularSearchTerms = data.StartPageModel?.items?.map(
            item => item?.PopularSearchTerms || ''
        )[0]?.split('\n') || [];
        searchSettings.searchPageUrl = data?.StartPageModel?.items?.[0]?.SearchResultPage?.Url || '';
    }

    return searchSettings;
}

export const SearchModal = () => {
    const customerContext = JSON.parse(
        window.sessionStorage.getItem(CUSTOMER_STATE_CONTEXT) ||
        '{}'
    )

    const activeCustomer = customerContext && customerContext.ActiveCustomer
    const features = (activeCustomer && activeCustomer.features) || []
    const customerType = (activeCustomer && activeCustomer.customerType) || ''

    const [results, setResults] = useState<SearchResultItem[]>([])

    const searchSettings = getSearchSettings();

    useEffect(() => {

    }, [searchSettings]);

    return (
        <div className={'search-modal hide'}>
            <div className={'search-modal__search-container'}>
                <Search
                    debounceT={300}
                    onAutoComplete={(value: string) => {
                        fetchSearchResults(
                            value,
                            features,
                            setResults,
                            customerType
                        )
                    }}
                    onClear={() => {
                        setResults([])
                    }}
                    recommendations={searchSettings.popularSearchTerms || []}
                    results={results}
                    searchPagePath={searchSettings.searchPageUrl || ''}
                    theme={SearchTheme.Borderless}
                />
            </div>
        </div>
    )
}

export default SearchModal
