import {createRoot} from "react-dom/client";
import React from "react";
import SearchModal from "@apps/SearchModal/SearchModal";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {I18nextProvider} from "react-i18next";
import i18n from "@apps/Shared/translation/i18n";

const container = document.getElementById('js-search-modal');

if (container) {
    const root = createRoot(container);
    const queryClient = new QueryClient()

    root.render(
        <I18nextProvider i18n={i18n}>
            <QueryClientProvider client={queryClient}>
                <SearchModal/>
            </QueryClientProvider>
        </I18nextProvider>
    );
}